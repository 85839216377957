'use strict';
/**
 * Renders a modal window that will show EOL Message and track consent
 */
module.exports = function () {
    $('.continue-eol').on('click', function (e) {
        e.preventDefault();
        var url = $(this).attr('data-action');
        if (url) {
            window.location.href = url;
        }
    });
};
