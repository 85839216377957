'use strict';

//policy & FAQ

var displayPolicyContent = function () {
	$('.left-section > ul:nth-of-type(1) > li:nth-child(1)').addClass('active');
	$('.right-section>  ul:nth-of-type(1) > li:first-child').addClass('active');
	$('.left-section > ul:nth-of-type(1) > li ').on('click', function (e) {
		e.preventDefault();
		var leftTabClicked = $(this);
		var href = $(this).find('a').attr('data-link');
		seturlPramsdetect(href);
		var index = (leftTabClicked.index() + 1);
		var rightTabContent = $('.tab-container > .right-section > ul:nth-of-type(1) > li:nth-child(' + index + ')');
		$(' #left-tab-policy  li').removeClass('active');
		$(' #right-tab-policy  li').removeClass('active');
		leftTabClicked.addClass('active');
		rightTabContent.addClass('active');
		if (!leftTabClicked[0].children[0].innerText === 'Policy-2') {
			$('#left-tab-policy')[0].removeClass('active');
		}
		$(window).scrollTop(0);
	});
	$('.left-section > ul:nth-of-type(2) > li ').on('click', function (e) {
		e.preventDefault();
		var leftTabClicked = $(this);
		var href = $(this).find('a').attr('data-link');
		seturlPramsdetect(href);
		var index = (leftTabClicked.index() + 1);
		var rightTabContent = $('.tab-container > .right-section > ul:nth-of-type(2) > li:nth-child(' + index + ')');
		$(' #left-tab-policy  li').removeClass('active');
		$(' #right-tab-policy  li').removeClass('active');

		leftTabClicked.addClass('active');
		rightTabContent.addClass('active');

		if (!leftTabClicked[0].children[0].innerText === 'Policy-2') {
			$('#left-tab-policy')[0].removeClass('active');
		}
		$(window).scrollTop(0);
	});

	$('.left-section > .toggle').on('click', function () {
		$(this).find(".down-arrow > .fa-caret-up").toggleClass("rotate_faq");

	})

}

var collapsiblefunction = function () {
	var coll = document.getElementsByClassName("collapsible");
	var i;

	for (i = 0; i < coll.length; i++) {
		coll[i].addEventListener("click", function () {
			this.classList.toggle("active");
			var content = this.nextElementSibling;
			if (content.style.display === "") {
				content.style.display = "none";
			} else {
				if (content.style.display === "block") {
					content.style.display = "none";
				}
				else {
					content.style.display = "block";
				}
			}
		});
	}
}

var changeUrlFaq = (function () {
	var url = new URL(window.location.href);
	var cid;
	var faqRes;
	if (url.href.includes('?')) {
		if (url.searchParams.length == undefined) {
			faqRes = url.search.split("=");
			cid = faqRes[1];
		}
		if (cid == null) {
			$("#left-tab-policy  li.active").trigger("click");
		}
		else {
			var $itemId = "." + cid;
			setTimeout(function () { $($itemId).parent('li').trigger("click"); }, 500);
		}
	}
	else {
		if (url.href.indexOf('?cid=') !== -1) {
			url = url.href.replace("?cid=", "/");
		}
		var cid = url.href.split("/").pop();


		if (cid == "policies-faqs") {
			$("#left-tab-policy  li.active").trigger("click");
		}
		else {
			var $itemId = "." + cid;
			setTimeout(function () { $($itemId).parent('li').trigger("click"); }, 500);
		}
	}
}());

var seturlPramsdetect = (function (href) {
	var oldUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
	var newurl;
	if (oldUrl.match(/([^\/]*)\/*$/)[1] == "policies-faqs") {
		newurl = oldUrl + '/' + href;
	}
	else {
		newurl = oldUrl.replace(
			new RegExp("(.*/)[^/]+$"),
			"$1" + href);
	}
	window.history.pushState({ path: newurl }, '', newurl);
});

var ikfaqLinks = function(){
   var headerHeight = $('header').outerHeight();
    $('.redirection_link').on('click', function(e) {
		e.preventDefault();
		var iklink = $(this).attr('href').replace("#","");
		$('html, body').animate({
			scrollTop: $('a[name= "'+iklink+'"]').offset().top - headerHeight - 15 + "px"
		}, 200);
		
	  });
}

var setPageTitle = function(){

	//check if it privacy policy page and set the page tilte
	var isPrivacyPolicy =  $('#isPrivacyPolicy');
	var privacyPolicyPageTitle =  $('#privacyPolicyPageTitle');

	if( isPrivacyPolicy.val() == 'true') {
		$('title').html(privacyPolicyPageTitle.val());
	}
 }
 


module.exports = {
	displayPolicyContent: displayPolicyContent,
	collapsiblefunction: collapsiblefunction,
	ikfaqLinks:ikfaqLinks,
	changeUrlFaq: changeUrlFaq,
	setPageTitle:setPageTitle
	

}