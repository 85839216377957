window.jQuery = window.$ = require('jquery');
var processInclude = require('./util');
$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('./components/readMore'));
    processInclude(require('base/components/cookie'));
    processInclude(require('./components/consentTracking'));
    processInclude(require('./components/policyConsent'));
    processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/ellipses.js'));
    processInclude(require('./components/search'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('./components/header.js'));
    processInclude(require('./components/faq.js'));
    processInclude(require('./product/productCarousel.js'));
    processInclude(require('./components/eolMessage'));
    $('body').on("click", ".Ikp-checkbox", function (evt) { 
        if ($(this).find('input').prop('checked')) { 
            $(this).closest('.agree-Ikp').find('a').removeClass('disabled');
        } else {
         $(this).closest('.agree-Ikp').find('a').addClass('disabled')
        }
      })
});

require('bootstrap/js/src/modal.js');
require('bootstrap/js/src/collapse.js');
require('./thirdParty/slick.js');
require('base/components/spinner');

