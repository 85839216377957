'use strict';

var searchActivate = function () {
	$("#seacrh_icon").click(function () {
		$(".search").slideToggle(100);
		$("#searchInput").focus();
		$(".user_links").toggleClass("add-arrow");
		$(".popover").removeClass("show");
	});
}
// code for closing sugeestions panel 
var closeSerachPanel = function (){
	$(".close_icon_search").click(function(){
		$(".search").slideToggle(100);
		$(".user_links").toggleClass("add-arrow");
     });
	 $('#maincontent').on('click', function (e) {
        $(".search").slideUp(100);
		$(".user_links").removeClass("add-arrow");
		$(".popover").removeClass("show");
    }); 
	$('body').on('click', function (e) {
		if (!$(e.target).closest('.user').length) {
			$('.user .popover').each(function () {
				// hide any open popovers when the anywhere else in the body is clicked
				if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
					$('.popover').removeClass('show');
				}
			});
		}
	});
	
}
//sticky header code
var stickyHeader = function () {
	let header = document.getElementsByTagName('header')[0];
	let successfulMsg = document.getElementById('succesful_msg');
	window.addEventListener('scroll', function () {
		if ($(window).scrollTop() >= 0) {
			$('header').addClass('sticky');
			$('#maincontent').css('padding-top', header.offsetHeight - successfulMsg.offsetHeight);
		}
		else {
			$('header').removeClass('sticky');
		}
	});
}

module.exports = {
	searchActivate: searchActivate,
	stickyHeader: stickyHeader,
	closeSerachPanel: closeSerachPanel

}