'use strict';

var base = require('base/product/base');
var focusHelper = require('base/components/focus');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append('<div class="row"> ' +
                '<div class="col-12 text-center"> ' +
                '<h1>' + data.resources.emptyCartMsg + '</h1> ' +
                '</div> ' +
                '</div>'
            );
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.summary-subtotal-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.totalNetPrice);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }
     $('.promotion-information').addClass('hide-promtion-discount');
    // For Cart Level Discount
    if (data.totals.discounts != null) {
        let cartDiscount = 0;
        if (data.totals.discounts[0] != null && data.totals.discounts[0].lineItemText != null){
            $('.promotion-name').text(data.totals.discounts[0].lineItemText);
            $('.applied-promotion-discount').text(data.totals.discounts[0].price);
            $('.promotion-information').removeClass('hide-promtion-discount');
        } else if (data.totals.discounts[1] != null && data.totals.discounts[1].lineItemText != null) {
            $('.promotion-name').text(data.totals.discounts[1].lineItemText);
            $('.applied-promotion-discount').text(data.totals.discounts[1].price);
            $('.promotion-information').removeClass('hide-promtion-discount');
            cartDiscount = Number(data.totals.discounts[1].price.substring(2));
        } else {
             $('.promotion-information').addClass('hide-promtion-discount');
            cartDiscount = -1;
        }
        if (data.totals.discounts[0] != null && data.totals.discounts[0].type === 'coupon' && data.totals.discounts[0].applied === true
            && ((cartDiscount == -1 && data.totals.orderLevelDiscountTotal.value > 0) || (cartDiscount > 0 && data.totals.orderLevelDiscountTotal.value > cartDiscount))
            ) {
            
            $('.order-discount').removeClass('hide-order-discount');
            $('.order-discount-total').empty()
                .append('-' + data.totals.orderLevelDiscountTotal.formatted);
        } else {
            $('.order-discount').addClass('hide-order-discount');
        }
        
    } else {
        $('.order-discount').addClass('hide-order-discount');
         $('.promotion-information').addClass('hide-promtion-discount');
    }

    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }
        if (item.renderedLearnersForm.prdType === 'cml') {
            if (item.quantity == 1) {
                $('.purchase-license-' + item.UUID).addClass('d-none');
            } else if (item.quantity > 1 && $('.purchase-license-' + item.UUID).hasClass('d-none')) {
                $('.purchase-license-' + item.UUID).removeClass('d-none');
            }
        }
        $('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
        $('.line-item-learnersform-' + item.UUID).empty().append(item.renderedLearnersForm.renderedLearnersForm);

        
    });


}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateIlt(data, $lineItem) {

    data.items.forEach(function (item) {
        var $lineItemId=$lineItem ;
        if (item.renderedLearnersForm.prdType === 'Ilt' && (item.UUID === $lineItem) ) {
            var $itemId="uuid-" + $lineItem ;
            var $currentLineItem = $('.'+$itemId);
            var availabilityCount = $currentLineItem.find($('.avail-count')).val();
            var waitlistCount = $currentLineItem.find($('.wait-count')).val();
            var sumOfAvailWait = $currentLineItem.find($('.availwait-count')).val();
            if (item.quantity <= availabilityCount) {
                $currentLineItem.find($('.avilable-waitlist-msg-' + item.UUID)).empty();
                $currentLineItem.find($('.avilable-msg-' + item.UUID)).addClass('avail-count').removeClass('waitlist-count');
                $currentLineItem.find($('.ilt-waitlist')).hide();
                if( $currentLineItem.find('.recipient_link span.avail-count').length >=1 ) {
                 $currentLineItem.find('.recipient_link span.avail-count').remove();
                } else {
                    $currentLineItem.find('.recipient_link span.avail-count').empty();
                }

                if( $currentLineItem.find('.recipient_link span.waitlist-count').length >=1 ) {
                    $currentLineItem.find('.recipient_link span.avail-count').remove();
                } else {
                       $currentLineItem.find('.recipient_link span.avail-count').empty();
                }
                $currentLineItem.find('.recipient_link span.waitlist-count').empty();
                $currentLineItem.find($('.info-icon.availCount')).hide();
                $currentLineItem.find($('.eol-msg')).hide();
                if(item.quantity == 1) {
                    if($currentLineItem.find('.recipient_link span.info-edit').length <= 1) {
                     $currentLineItem.find('.recipient_link').find('.info-edit').show();
                    } else {
                        $currentLineItem.find('.recipient_link').find('.info-edit:first').hide();  
                    }
                } else {
                    if($currentLineItem.find('.recipient_link span.info-save').length <= 1) {
                     $currentLineItem.find('.recipient_link').find('.info-save').show();
                    } else {
                     $currentLineItem.find('.recipient_link').find('.info-save').show();   
                     $currentLineItem.find('.recipient_link').find('.info-save:first').hide();
                    }
                }
                if( $currentLineItem.find('.recipient_link .down-arrow').length == 2 ) {
                    $currentLineItem.find('.recipient_link .down-arrow').removeClass('d-none');
                    $currentLineItem.find('.recipient_link .down-arrow').first().addClass('d-none')
                } else {
                    $currentLineItem.find('.recipient_link .down-arrow').removeClass('d-none').attr('style','');
                }
                $(".info-icon.waitlistCount.waitlistCount").addClass('d-none');
                $currentLineItem.find('.row.description').removeClass('disabled');
                $currentLineItem.find('.value.content').removeClass('d-none');
                
            } else if (item.quantity > availabilityCount && waitlistCount > 0 && item.quantity <= sumOfAvailWait) {
                if( $currentLineItem.find('.recipient_link span.avail-count').length <= 1 ) {
                    $currentLineItem.find('.recipient_link span.avail-count').remove();
                    $currentLineItem.find('.recipient_link').prepend('<span class="avail-count">'+data.resources.waitlistAvailable +'</span>');
                }
                if ($currentLineItem.find('.recipient_link .info-icon.availCount').length > 1 ) {
                  $currentLineItem.find('.recipient_link .info-icon.availCount:first').remove();
                }
                $currentLineItem.find('.recipient_link').find('.info-icon.availCount').removeClass('d-none');
                $currentLineItem.find('.recipient_link').find('.waitlist-count').addClass('d-none');
                $currentLineItem.find('.recipient_link').find('.info-icon.waitlistCount').addClass('d-none');
                $currentLineItem.find('.info-icon.availCount').show();
                $currentLineItem.find($('.avilable-msg-' + item.UUID)).addClass('waitlist-count').removeClass('avail-count');
                $currentLineItem.find($('.ilt-waitlist')).show();
                $currentLineItem.find($('.eol-msg')).show();
                $currentLineItem.find('.value.content').removeClass('d-none');
                $currentLineItem.find('.row.description').removeClass('disabled');
                if( $currentLineItem.find('.recipient_link .down-arrow').length == 2 ) {
                    $currentLineItem.find('.recipient_link .down-arrow').removeClass('d-none');
                    $currentLineItem.find('.recipient_link .down-arrow').first().addClass('d-none')
                } else {
                    $currentLineItem.find('.recipient_link .down-arrow').removeClass('d-none').attr('style','');
                }
                if(item.quantity == 1) {
                    if($currentLineItem.find('.recipient_link span.info-edit').length <= 1) {
                     $currentLineItem.find('.recipient_link').find('.info-edit').show();
                    } else {
                        $currentLineItem.find('.recipient_link').find('.info-edit:first').hide();  
                    }
                } else {
                    if($currentLineItem.find('.recipient_link span.info-save').length <= 1) {
                     $currentLineItem.find('.recipient_link').find('.info-save').show();
                    } else {
                     $currentLineItem.find('.recipient_link').find('.info-save').show();   
                     $currentLineItem.find('.recipient_link').find('.info-save:first').hide();
                    }
                }
                
            } else if (item.quantity > sumOfAvailWait || waitlistCount == 0) {
                if( $currentLineItem.find('.recipient_link span.avail-count').length <= 1 ) {
                    $currentLineItem.find('.recipient_link span.avail-count').remove();
                    if( $currentLineItem.find('.recipient_link span.waitlist-count').length < 1 ){
                     $currentLineItem.find('.recipient_link').prepend('<span class="waitlist-count">'+data.resources.waitlistUnavailable +'</span>');
                    } else {
                        $currentLineItem.find('.recipient_link span.waitlist-count').html(data.resources.waitlistUnavailable).removeClass('d-none');
                    }
                }
                if ($currentLineItem.find('.recipient_link .info-icon.waitlistCount').length > 1 ) {
                  $currentLineItem.find('.recipient_link .info-icon.waitlistCount:first').remove();
                }
                $currentLineItem.find('.recipient_link').find('.info-icon.waitlistCount').removeClass('d-none');
                $currentLineItem.find('.recipient_link').find('.info-save').hide();
                $currentLineItem.find('.recipient_link').find('i').addClass('d-none');
                $currentLineItem.find('.info-icon.availCount').hide();
                $currentLineItem.find('.value.content').addClass('d-none');
                $currentLineItem.find($('.avilable-msg-' + item.UUID)).addClass('waitlist-count').removeClass('avail-count');
                $currentLineItem.find('.row.description').addClass('disabled');
            }
        }
    });
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';
    $('.cart-error').html(errorHtml);
    
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $('.availability-' + lineItem.UUID).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach(function (message) {
                    messages += '<p class="line-item-attributes">' + message + '</p>';
                });
            }

            if (lineItem.availability.inStockDate) {
                messages += '<p class="line-item-attributes line-item-instock-date">'
                    + lineItem.availability.inStockDate
                    + '</p>';
            }
        }

        $('.availability-' + lineItem.UUID).html(messages);
    }
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) { // eslint-disable-line no-unused-vars
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    $('.card.product-info.uuid-' + uuid).replaceWith(data.renderedTemplate);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
            $('body').trigger('editproductmodal:ready');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    $productToRemoveSpan.empty().append(productName);
}

/**
 * @param {string} eolUrl - url to check the availablility of eol products in the cart
*/
function getEOLMessage(eolUrl, redirectUrl) {
    var url = eolUrl
    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        success: function (data) {
            if (data.countEOLProduct > 0) {
                if (data.countEOLProduct  == 1) {
                    $('.eolwrapper .modal-title').text(data.popupHeading);
                } else {
                    $('.eolwrapper .modal-title').text("");
                }
                $('.eolwrapper .modal-body').text(data.popupBody);
                
                $('.eolwrapper').modal({
                    show: true,
                    backdrop: 'static',
                    keyboard: false
                });
                $('body').css('padding-right','0px');
                $('body').removeClass('modal-open');
            } else {
                window.location.href = redirectUrl;
            }
        },
        error: function (err) {
            window.location.href = redirectUrl;
        }
    });
}

/**
 * @param {string} blockUrl - url to trigger the Seat Block API
*/
function callSeatBlockAPI (blockUrl, seatBlockInput) {
    var sum = 0;
    var learnerResArray = [];
    var errorResArray = [];
    var result = {};
    var seatBlockSuccess = true;
    var seatBlockInputArray =  JSON.parse(seatBlockInput);
    for (var i=0; i < seatBlockInputArray.length; i++) {
        var seatBlockInput = seatBlockInputArray[i];
        var pid = seatBlockInput.pid;
        var individualArray = JSON.parse(seatBlockInput.learnerObj);
        sum = sum + individualArray.length;
        for (var j = 0; j < individualArray.length; j++) {
            var learnerDataObj = individualArray[j]
            var req = { 
                    pid : pid,
                    learnerObj : JSON.stringify(learnerDataObj)
                };
            $.ajax({
                url: blockUrl,
                type: 'post',
                async: false,
                dataType: 'json',
                data : req,
                success: function (data) {
                    if (!data.success) {
                        errorResArray.push(data.errorMsg);
                    } else {
                        learnerResArray.push(data.userdetail);
                            //window.location.href = redirectUrl;
                    }   
                },
                error: function (err) {
                    result.success = false;
                    result.errorMsg = err.responseJSON.message;
                    return;
                }
            });
           /* if (sum != learnerResArray.length) {
                seatBlockSuccess = false;
                break;
            }*/
        }
        if (sum != learnerResArray.length) {
            seatBlockSuccess = false;
            break;
        }
    }

    if (seatBlockSuccess) {
        result.success = true;
        result.resArray = learnerResArray;
    } else {
        result.success = false;
        if(errorResArray.length > 0){
            result.errorMsg = errorResArray[0]; 
        }else{
            result.errorMsg = 'Seat Block Validation Failed!'; 
        }
    }
    return result;
}

module.exports = function () {
    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        confirmDelete(actionUrl, productID, productName, uuid);
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('.optional-promo').click(function (e) {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();

        $('body').trigger('cart:beforeUpdate');
        var formData = $('input[name=learnerEmail]');
        var formDataCCSI = $('input[name=learnerCCSI]');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.basket.items.length === 0) {
                    location.reload();
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }
                    $('.uuid-' + uuid).remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    updateCartTotals(data.basket);
                    updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    validateBasket(data.basket);
                }

                $('body').trigger('cart:update', data);
                setFieldsData(formData, formDataCCSI);
                checkForInvalid(data.basket);
                allFieldsValid();
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
                setFieldsData(formData, formDataCCSI);
                checkForInvalid(data.basket);
                updateFormTitle(uuid);
            }
        });
    });

    $('body').on('change', '.quantity-form > .quantity', function (e) {

        e.preventDefault();
        validateQty($(this));
        
    });
    $('body').on('blur', '.quantity-form > .quantity', function (e) {

        e.preventDefault();
        validateQty($(this));
        
    });

    function validateQty(element)
    {
        
        $("[id=choose]").css("background-color", "yellow");
        var preSelectQty = element.data('pre-select-qty');
        var quantity = element.val();
        var productID = element.data('pid');
        var url = element.data('action');
        var uuid = element.data('uuid');
        var $lineItem= element.parent().closest('.product-info').data('uuid');
        element.parent().closest('.product-info').find('.non-cml-product').addClass('d-none');
        if(element.data('producttype') === 'dae'){
           if( quantity > element.data('daemaxqty')){
            quantity = parseInt(element.data('daemaxqty'));
            $("#error-daemax-quantities").modal('show'); 
           }
           else{
               if(quantity == 0){
                quantity = parseInt(element.data('daemaxqty'));
                $("#error-zero-quantities").modal('show');
               }
           }
        }
        else{
            if(quantity > 100  || quantity < 1){    
                $("#error-max-quantities").modal('show');
                quantity = parseInt(preSelectQty);
            } 
        }    
        
        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);
        element.parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');
        var formData = $('input[name=learnerEmail]');
        var formDataCCSI = $('input[name=learnerCCSI]');

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                updateAvailability(data, uuid);
                updateIlt(data, $lineItem);
                validateBasket(data);
                element.data('pre-select-qty', quantity);
                if ( quantity > 1) {
                    $(".checkout-btn").addClass('disabled');
                }
               
                $('body').trigger('cart:update', data);
                var $currentLineItem=element.parent().closest('.product-info').find('.learnerValidation');
                var $currentPurchase = $currentLineItem.find('.purchase-license-checkbox');
                var $isPurchaseClicked = $currentPurchase.find('label').hasClass('checked');
                var defaultEmail = $currentLineItem.find('.purchase-license-checkbox input').data('email');
                if ($isPurchaseClicked) {
                    $currentLineItem.find('input.learners-email').each(function (i) {
                        $(this).val(defaultEmail);
                        $(this).attr("disabled", "true");
                        if($(this).hasClass('is-invalid')) {
                            $(this).removeClass('is-invalid');
                            $(this).parent().find('.invalid-feedback').html("");
                        }
                    });
                }
                $.spinner().stop();
                if (element.parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    location.reload();
                }
                setFieldsData(formData, formDataCCSI);
                updateFormTitle(uuid);
                checkForInvalid(data);
                allFieldsValid();
                
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    element.val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
                setFieldsData(formData, formDataCCSI);
                updateFormTitle(uuid);
                checkForInvalid(data);
            }
            
        });
    }

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $('body').trigger('cart:beforeShippingMethodSelected');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }

                $('body').trigger('cart:shippingMethodSelected', data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit(function (e) {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();
        $('body').trigger('promotion:beforeUpdate');
        var formData = $('input[name=learnerEmail]');
        var formDataCCSI = $('input[name=learnerCCSI]');

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                    $('body').trigger('promotion:error', data);
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    $('body').trigger('promotion:success', data);
                    $(".promo-code-form").addClass('d-none');
                    setFieldsData(formData, formDataCCSI);
                    checkForInvalid(data);
                    allFieldsValid();
                }
                $('.coupon-code-field').val('');
                $.spinner().stop();
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
                setFieldsData(formData, formDataCCSI);
                checkForInvalid(data);
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
        $(".promo-code-form").removeClass('d-none');
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();
        var formData = $('input[name=learnerEmail]');
        var formDataCCSI = $('input[name=learnerCCSI]');

        $.spinner().start();
        $('body').trigger('promotion:beforeUpdate');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);
                $.spinner().stop();
                setFieldsData(formData, formDataCCSI);
                checkForInvalid(data);
                allFieldsValid();
                $(".promo-code-form").removeClass('d-none');
                $('body').trigger('promotion:success', data);
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
                setFieldsData(formData, formDataCCSI);
                checkForInvalid(data);
            }
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('shown.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#editProductModal', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr('disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available')
        );
    });

    $('body').on('input', '#couponCode', function () {  
        if ($(this).val().length != 0) {
            $(':input[type="submit"]').prop('disabled', false);
        }
        else {
            $(':input[type="submit"]').prop('disabled', true);
        }
    });

    $('body').on('product:updateAvailability', function (e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);


        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('available'); });

            var allReady = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('ready-to-order'); });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('change', '.options-select', function () {
        var selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });


    $(".recipient_link").click(function () {
        if (!($(this).next().hasClass('d-none'))) {
            $('.server-down-msg').addClass('d-none');
        }
        $(this).find($(".down-arrow")).toggleClass("rotate-up");
    });


    //sticky procced to checkout
    $( document ).ready(function() {
        if ($('.page').data('action') == "Cart-Show") {
            if ($('#checkout-btn').hasClass('checkout-continue')) { 
                $("#footercontent").addClass("sticyBtn");
            }
            else{
                $("#footercontent").addClass("nt-stickyBtn");
            }
        }
    });

     //Checking purchase license option when returning from checkout page
    $( document ).ready(function() {
        setTimeout(function(){ 
            $( ".card.product-info" ).each(function( index ) {
                if(($(this).find('.purchase-license-checkbox').hasClass('cmlForSelf'))) {
                $(this).find('.purchase-license-checkbox .cln-checkbox').trigger('click');
                $(this).find('.purchase-license-checkbox label').removeClass('unChecked');
                $(this).find('.purchase-license-checkbox label').addClass('checked');
                }
            }); 
       }, 1000);
    });

    //for validating all fields and enabling the proceed to checkout button
    function allFieldsValid () {
        var enableCheckout = true;
        var learnerCCSI = true;
        var allFields = $('input[name=learnerEmail]');
        $(allFields).each(function (i) {
            var value = $(this).val();
            if (value.length == 0 || value == "" || ($(this).hasClass('is-invalid'))) {
                enableCheckout = false;
                return true;
            }
        });
        var learnerEmails=$('input[name=learnerCCSI]');
        $(learnerEmails).each(function (i) {
            var value = $(this).val();
            if (value.length == 0 || value == "" || ($(this).hasClass('is-invalid'))) {
                learnerCCSI = false;
                return true;
            }
        });
        if (enableCheckout && learnerCCSI) {
            $(".checkout-btn").removeClass('disabled');
        } else {
            $(".checkout-btn").addClass('disabled');
        }
    }
    function updateFormTitle (uuid) {
        //Updating the Learners form title
        var $currentLineItem = $('.line-item-learnersform-' + uuid).parent().closest('.product-info').find('.learnerValidation');
        var empty = false;
        $currentLineItem.find('input.learners-email').each(function(i) {
            var value = $(this).val();
            if (value.length == 0 || value == "" ) {
                empty = true;
                return true;
            }
        });
        if (empty == true) {
            $currentLineItem.find($('.info-edit')).addClass("d-none");
            $currentLineItem.find($('.info-save')).addClass("d-lock").removeClass('d-none');
        } else {
            $currentLineItem.find($('.info-save')).addClass("d-none");
            $currentLineItem.find($('.info-edit')).addClass("d-lock").removeClass('d-none');
        }
        var learners = $currentLineItem.find('input.learners-email');
        var learnersCount = learners.length;
        var firstLearner = learners[0].value.toLowerCase();
        var duplicateEmailFound = false;
        var isCml= $currentLineItem.hasClass('cmlProduct');
        if (learnersCount > 1) {
            for (var y = 1; y < learnersCount; y++) {
                if (learners[y].value != '' && firstLearner == learners[y].value.toLowerCase()) {
                    duplicateEmailFound = true;
                    if(!isCml) {
                     learners[y].classList.add("is-invalid");
                    }
                    break;
                } 
            }
        }
        if (!isCml && duplicateEmailFound) {
            $currentLineItem.find('.non-cml-product').removeClass('d-none');
        }
    }
    function checkForInvalid(data) {
        data.items.forEach(function (item) {
        //Updating the productLine invalid errors if the form has duplicates emails for non cml products
         var $currentLineItem = $('.line-item-learnersform-' + item.UUID).parent().closest('.product-info').find('.learnerValidation');
          var learners = $currentLineItem.find('input.learners-email');
          var learnersCount = learners.length;
          var firstLearner = learners[0].value.toLowerCase();
          var duplicateEmailFound = false;
          var isCml= $currentLineItem.hasClass('cmlProduct');
          if (learnersCount > 1) {
              for (var y = 1; y < learnersCount; y++) {
                  if (learners[y].value != '' && firstLearner == learners[y].value.toLowerCase()) {
                      duplicateEmailFound = true;
                      if(!isCml) {
                       learners[y].classList.add("is-invalid");
                      }
                      break;
                  } 
              }
          }
          
        });
    }

    //closing alert error pop ups'
    $(document).on('click', '.alert-danger button', function (){
       $(this).parent().removeClass('show');
    })

    // custom validation code for product line item  
    $(document).on('focusin', '.learners-email', function () {
        if ($(this).hasClass('focused-out')) {
            $(this).removeClass('focused-out');
        }
    })
    $(document).on('focusout', '.learners-email', function () {
        if (!$(this).hasClass('focused-out')) {
            var email = $(this).val();
            var $current = $(this);
            $current.parent().find('.invalid-feedback').html("");
            $(this).addClass('focused-out');
            var lblError = $(this).next('.invalid-feedback');
            lblError.innerHTML = '';
            var expr = /^[\w.%+-\.'']+@[\w.'\-]+.[\w]{2,15}$/;
            var pattern = $current.attr('pattern');
            if (pattern != null) {
                expr = new RegExp(pattern);
            }
        
            if (!expr.test(email) && !$current.hasClass('learner-ccsi')) {
                $(this).addClass('is-invalid');
                lblError.html('Invalid Email');
                
            } else {
                $(this).removeClass('is-invalid');
                lblError.html('');
            }

            if (email == '') {
                $(this).addClass('is-invalid');
                if(!$current.hasClass('learner-ccsi')) {
                 lblError.html('Email Required');
                } else {
                  lblError.html('CCSI required'); 
                }
            }

            var empty = false;
            var $form = $(this).closest('.learnerValidation');
            var duplicateEmail = false;
            var currentId = $(this).attr('id');
            
            var learners = $form.find('input.learners-email');
            var learnersCount = learners.length;
            var firstLearner = learners[0].value.toLowerCase();
            var differentEmailPresent = false;
            var duplicateEmailFound = false;
            if (learnersCount > 1) {
                for (var z = 1; z < learnersCount; z++) {
                    if (learners[z].value != '' && firstLearner != learners[z].value.toLowerCase()) {
                        differentEmailPresent = true;
                        break;
                    } 
                }
                for (var y = 1; y < learnersCount; y++) {
                    if (learners[y].value != '' && firstLearner == learners[y].value.toLowerCase()) {
                        duplicateEmailFound = true;
                        break;
                    } 
                }
            }
            $form.find('input.learners-email').each(function(i) {
                var value = $(this).val();
                var isCml= $form.hasClass('cmlProduct');
                if (value.length == 0 || value == "" || ($(this).hasClass('is-invalid'))) {
                    empty = true;
                    return true;
                } else if (!isCml && ($(this).val().toLowerCase() == $current.val().toLowerCase() && $(this).attr('id') != currentId)) {
                    duplicateEmail = true;
                    var $emailDuplicate = $form.data('duplicate-email');
                    $current.addClass('is-invalid');
                    $current.parent().closest('.learnerValidation').find('.non-cml-product').removeClass('d-none');
                    return true;
                } else if (isCml) {
                    if (duplicateEmailFound && differentEmailPresent) {
                        duplicateEmail = true;
                        var $emailDuplicate = $form.data('duplicate-email');
                        $current.addClass('is-invalid');
                        $current.parent().closest('.learnerValidation').find('.non-cml-product').removeClass('d-none');
                        return true;
                    } else if (!duplicateEmailFound && ($(this).val().toLowerCase() == $current.val().toLowerCase() && $(this).attr('id') != currentId)) {
                        duplicateEmail = true;
                        var $emailDuplicate = $form.data('duplicate-email');
                        $current.addClass('is-invalid');
                        $current.parent().closest('.learnerValidation').find('.non-cml-product').removeClass('d-none');
                        return true;
                    } else if (!differentEmailPresent && ($(this).val().toLowerCase() != $current.val().toLowerCase() && $(this).attr('id') != currentId)) {
                        duplicateEmail = true;
                        var $emailDuplicate = $form.data('duplicate-email');
                        $current.addClass('is-invalid');
                        if(!$form.find('.is-invalid').length){
                            $current.parent().closest('.learnerValidation').find('.non-cml-product').removeClass('d-none');
                        }
                       
                        return true;
                    }
                } else {
                    return true;
                }
              
            });
            allFieldsValid();
            if (empty == false) {
                if(!duplicateEmail) {
                 $current.parent().closest('.learnerValidation').find('.non-cml-product').addClass('d-none');
                }
                $form.find($('.info-save')).addClass("d-none");
                if($form.find($('.info-edit')).length == 1 ) {
                  $form.find($('.info-edit')).addClass("d-lock").removeClass('d-none');
                } else {
                  $form.find($('.info-edit')).eq(2).addClass("d-lock").removeClass('d-none').attr('style','');  
                }
            }
        }
    });

    //handling purchase license option and filling the email with default account email
    $('body').on("click", ".learnerValidation .purchase-license-checkbox", function (evt) {
        evt.stopPropagation();
        var defaultEmail = $(this).find('input').data('email');
        var $label = $(this).find('label');
        var $siblingForm = $(this).closest('.learnerValidation');
        if ($(this).find('input').prop('checked')) {

            $label.removeClass('unChecked');
            $label.addClass('checked');
            $siblingForm.find('input.learners-email').each(function (i) {
                $(this).val(defaultEmail);
                $(this).attr("disabled", "true");
                if($(this).hasClass('is-invalid')) {
                    $(this).removeClass('is-invalid');
                    $(this).parent().find('.invalid-feedback').html("");
                }
            });
            allFieldsValid();
            $siblingForm.find($('.recipient_link')).trigger('click');
            $siblingForm.find($('.info-save')).addClass("d-none");
            $siblingForm.find($('.info-edit')).addClass("d-lock").removeClass('d-none');
        } else {
            $label.removeClass('checked');
            $label.addClass('unChecked');
            $siblingForm.find($('.non-cml-product')).addClass("d-none");
            $siblingForm.find('input.learners-email').each(function (i) {
                $(this).removeAttr("disabled");
                if (i > 0) {
                    $(this).val("");
                }
            });
            allFieldsValid();
            $siblingForm.find($('.recipient_link')).trigger('click');
            $siblingForm.find($('.info-save')).removeClass("d-none");
            $siblingForm.find($('.info-edit')).addClass("d-none").removeClass('d-block');
        }
    });

    //Setting fields data when ajax call happening
    function setFieldsData(formData, formDataCCSI) {
        var formDataArray = [];
            var formObj = {};
            if(formData.length > 0){
                for (var i = 0; i < formData.length; i++) {;
                    formObj = {
                        email: formData[i].value,
                        lineItemId: formData[i].dataset.uuid,
                        fieldId: formData[i].id
                    };
                    formDataArray.push(formObj);
                }
            } 
            if (formDataCCSI.length > 0) {
                //For ICP Product fetch CCSI fields
                var formDataCCSIArray = [];
                var formCCSIObj = {};
                for (var j = 0; j < formDataCCSI.length; j++) {
                    var CCSIField = formDataCCSI[j];
                    var fieldId=formDataCCSI[j].id ;
                    var lineItemId= formDataCCSI[j].dataset.uuid ;
                    var CCSIEmailField = CCSIField.closest('.request-lineItemForm-body').getElementsByClassName('learners-email')[0];
                    formCCSIObj = {
                        ccsi: CCSIField.value,
                        ccsiEmail: CCSIEmailField.value,
                        lineItemId: lineItemId,
                        fieldId: fieldId
                    };
                    formDataCCSIArray.push(formCCSIObj);
                }
            }
            $.each(formDataArray, function( key, value ) {
                $('.uuid-' + formDataArray[key].lineItemId + " " + 'input').each(function() {
                    if( $(this).attr('id') == formDataArray[key].fieldId ) {
                        $(this).val(formDataArray[key].email);
                    }
                });
            }
            );

            $.each(formDataCCSIArray, function( key, value ) {
                $('.uuid-' + formDataCCSIArray[key].lineItemId + " " + 'input').each(function() {
                    if( $(this).attr('id') == formDataCCSIArray[key].fieldId ) {
                        if($(this).attr('name') == 'learnerEmail') {
                            $(this).val(formDataCCSIArray[key].ccsiEmail);
                        } else {
                            $(this).val(formDataCCSIArray[key].ccsi);
                        }
                    }
                });
            }
            );
              
    }

    $('.checkout-btn').on('click', function (e) {
        e.preventDefault();
        var $form = $(this);
        var redirectUrl = $form.attr('href');
        var url = $form.attr('data-action');
        var eolUrl = $form.attr('data-eolUrl');
        var blockUrl = $form.attr('data-block');
        if (url) {
            var formDataArray = [];
            var formObj = {};
            var formData = $('input[name=learnerEmail]');
            if(formData.length > 0){
                var formDataCCSI = $('input[name=learnerCCSI]');
                var ccsiPresent = false;
                
                for (var i = 0; i < formData.length; i++) {;
                    formObj = {
                        email: formData[i].value,
                        lineItemId: formData[i].dataset.uuid
                    };
                    formDataArray.push(formObj);
                }
                
                if (formDataCCSI.length > 0) {
                    //For ICP Product fetch CCSI fields
                    ccsiPresent = true;
                    var formDataCCSIArray = [];
                    var formCCSIObj = {};
                    for (var j = 0; j < formDataCCSI.length; j++) {
                        var CCSIField = formDataCCSI[j];
                        var CCSIEmailField = CCSIField.closest('.request-lineItemForm-body').getElementsByClassName('learners-email')[0];
                        formCCSIObj = {
                            ccsi: CCSIField.value,
                            ccsiEmail: CCSIEmailField.value,
                            lineItemId: CCSIField.dataset.uuid
                        };
                        formDataCCSIArray.push(formCCSIObj);
                    }
                }
                
                var urlParams = {};
                if (ccsiPresent) {
                    urlParams = {
                        formDataArray: JSON.stringify(formDataArray),
                        formDataCCSIArray: JSON.stringify(formDataCCSIArray),
                        ccsiPresent: ccsiPresent
                    };
                } else {
                    urlParams = {
                        formDataArray: JSON.stringify(formDataArray),
                        ccsiPresent: ccsiPresent
                    };
                }
                
                url = appendToUrl(url, urlParams);
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    data: $('[name$="csrf_token"]').serialize(),
                    success: function (data) {
                        if (!data.success) {
                            $('.server-down-msg').removeClass('d-none');
                        } else {
                            var response = data.response;
                            var learnersValidated = response.learnersValidated;
                            if (!learnersValidated) {
                                $(".checkout-btn").addClass('disabled');
                                var invalidEmails = response.invalidEmails;
                                if (invalidEmails != null && invalidEmails.length > 0) {
                                    for (var i = 0; i < formData.length; i++) {
                                        var email = formData[i].value;
                                        for (var j = 0; j < invalidEmails.length; j++) {
                                            if (email.toLowerCase() == invalidEmails[j].toLowerCase()) {
                                                formData[i].classList.add('is-invalid');
                                                formData[i].nextElementSibling.innerHTML = 'Invalid Email';
                                                break;
                                            }
                                        }
                                    }
                                }
                                var invalidCCSI = response.invalidCCSI;
                                if (invalidCCSI != null && invalidCCSI.length > 0) {
                                    for (var i = 0; i < formDataCCSI.length; i++) {
                                        var CCSIID = formDataCCSI[i].value;
                                        for (var j = 0; j < invalidCCSI.length; j++) {
                                            if (CCSIID == invalidCCSI[j]) {
                                                formDataCCSI[i].classList.add('is-invalid');
                                                formDataCCSI[i].nextElementSibling.innerHTML = 'Invalid CCSI ID';
                                                break;
                                            }
                                        }
                                    }
                                }
                            } else {
                                // if no invalid emails and the data is saved
                                //Call the EOL function
                                if (blockUrl && $(document).find('.avail-count').length > 0) {
                                    if (response.seatBlockInput != null) {
                                        var seatBlockInput = response.seatBlockInput;
                                        var seatBlockResponse = callSeatBlockAPI(blockUrl, seatBlockInput);
                                        if (seatBlockResponse.success) {
                                            //Success flow of Seat Block Call
                                            if (eolUrl) {
                                                getEOLMessage(eolUrl, redirectUrl);
                                            } else {
                                                window.location.href = redirectUrl;
                                            }
                                        } else {
                                            createErrorNotification(seatBlockResponse.errorMsg);
                                        }
                                    }
                                } else {
                                    //seat block is not applicable
                                    if (eolUrl) {
                                        getEOLMessage(eolUrl, redirectUrl);
                                    } else {
                                        window.location.href = redirectUrl;
                                    }
                                }
                            }
                        }
                    },
                    error: function (err) {
                    }
                });
            }else{
                window.location.href = redirectUrl;
            }
        } else {
            window.location.href = redirectUrl;
        }
        return false;
    });


    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        var selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
        var uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOptionValueId
        };

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('#editProductModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    validateBasket(data.cartModel);

                    $('body').trigger('cart:update', data);

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    // Add to cart from recommendation section
    $('body').on('click', '.cart-recomendations .add-to-cart', function (e) {
        var tile = require('../product/tile');
        tile.addToCart();
        setTimeout(function () {
            location.reload();
        }, 500);
    });

    $('.iltclose').on('click' , function () {
    	$(this).closest('.valid-cart-error').remove();
    });

    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
};