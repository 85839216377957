module.exports = function () {
    $(".product-description-long").hide();
    $('.show_hide').on('click' ,function(){
        var txt = $(".product-description-long").is(':visible') ? 'Read more' : 'Read less';
        $(".show_hide").text(txt);
        $(this).parent().find('.product-description-long').toggle();
    });

    //pdp page ReadMore
    $(".more_link").on("click", function () {
        $(".product-description-long-pdp").show();
        $(".more_link").hide();
        $(".less_link").show();
    });
    $(".less_link").on("click", function (e) {
        e.preventDefault();
        $(".product-description-long-pdp").hide();
        $(".less_link").hide();
        $(".more_link").show();
        var targetOffset = $("#overview-accordion").offset().top - 150;
         $('html, body').animate({
           'scrollTop' : targetOffset
		}, 200);	
    });

    //pdp overview accordion

    $(".description-and-detail .title").on("click", function () {
        $(".product-description-long-pdp").hide();
        $(".more_link").show(); 
    });
}